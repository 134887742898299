<template>
  <Details
    ref="details"
    title="Application"
    resource="apps"
    :base-path="basePath"
    routeIdParam="appId"
    title-template="{name} application"
  >
    <template v-slot:side-panel="{ record }">
      <DetailsSidePanel entity-title="application" :view-mode="Boolean(record.deletedAt)"></DetailsSidePanel>
    </template>
    <template v-slot:default="detailsProps">
      <ApplicationForm
        :initialValues="getInitialValues(detailsProps.record)"
        :on-submit="detailsProps.handleSubmit"
        :loading="detailsProps.loading"
        @close="detailsProps.redirectToList"
      />
    </template>
  </Details>
</template>

<script>
  import ActionsMixin from '@/mixins/ActionsMixin';
  import ModalNavigation from '@/mixins/ModalNavigation';
  import NotifyMixin from '@/mixins/NotifyMixin';
  import Details from '@/components/auth/details/Details';
  import DetailsSidePanel from '@/components/auth/details/DetailsSidePanel';
  import ApplicationForm from '@/views/auth/applications/ApplicationForm';
  import { ROLES } from '@/components/auth/constants';

  export default {
    name: 'ApplicationsEdit',
    components: {
      Details,
      DetailsSidePanel,
      ApplicationForm,
    },
    mixins: [
      ActionsMixin,
      ModalNavigation,
      NotifyMixin,
    ],
    computed: {
      appId() {
        return this.$route.params.appId;
      },
      basePath: function () {
        const view = this.$route.name.includes('deletedApplications') ? 'details' : 'edit';
        return this.$route.name.replace(view, 'index');
      },
    },
    methods: {
      getInitialValues(initialValues) {
        const {
          appInfo,
          attributes: initAttributes = [],
          bundle,
          isCustomerUserAgnostic,
          isCommunityAgnostic,
          ...values
        } = initialValues;

        const appId = initialValues.id;
        const metaroles = bundle?.metaroles?.reduce((acc, item) => {
          const appRoleId = item.roles.find(role => role.appId === appId)?.id || ROLES.NO_ROLE;
          acc[item.id] = {
            ...item,
            appRoleId,
          };
          return acc;
        }, {}) || {};

        const attributes = initAttributes.map((item) => {
          if (item.allowedValues === 'true|false') { // Agreed on BE response. TODO: enhance value validation and types
            item.isBoolean = true;
          }
          return item;
        });

        return {
          ...values,
          appInfo,
          isCommunityAgnostic: appInfo !== null && appInfo?.isCommunityAgnostic !== null
            ? appInfo?.isCommunityAgnostic
            : isCommunityAgnostic,
          isCustomerUserAgnostic: appInfo !== null && appInfo?.isCustomerUserAgnostic !== null
            ? appInfo?.isCustomerUserAgnostic
            : isCustomerUserAgnostic,
          bundleId: bundle?.id,
          bundleType: bundle?.type,
          metaroles,
          attributes,
        }
      },
      deleteApp() {
        this.$authDataProvider.delete('apps', {id: this.appId})
          .then(() => {
            this.notifySuccess('application deleted');
            this.$refs.details.redirectToList();
          })
          .catch(error => this.notifyError(error.message));
      },
    },
    mounted() {
      this.$watch('$refs.details.record', function ({ deletedAt }) {
        if (!deletedAt) {
          this.addConfirmAction(
              {
                id: 'auth-delete-app',
                title: "delete application",
                showConfirmation: true,
                confirmationMessage: 'Are you sure you want to delete this application?',
                confirmationType: 'warning',
                confirmBtnText: 'yes',
                cancelBtnText: 'no'
              },
              this.deleteApp
          )
        }
      });
    }
  };
</script>

<style scoped>

</style>
